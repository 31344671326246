import React from "react";
import * as SurveyCreator from "survey-creator-react";
import "./CustomToolboxWrapper.css";

export default function CustomToolboxWrapper(props) {
  return (
    <React.Fragment>
      <div className="toolbox">
        <div className="toolbox-title">Select your question type below.</div>

        <SurveyCreator.AdaptiveToolbox
          {...props}
        ></SurveyCreator.AdaptiveToolbox>
      </div>
    </React.Fragment>
  );
}
