import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import {
  getQuiz,
  completeQuizAttempt,
  getCompleteQuizAttempts,
  putQuizAttempt,
} from "../../api/quiz";
import TokenService from "../../utils/TokenService";
import * as Survey from "survey-core";
import * as ReactSurvey from "survey-react-ui";
import * as css from "survey-core/defaultV2.css";

import "./Quiz.css";

Survey.StylesManager.applyTheme("defaultV2");

const INTERVAL_LENGTH = 1000 * 60;

const SurveyComponent = () => {
  const [model, setModel] = useState(undefined);
  const [error, setError] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const user = TokenService.getUser();
  const { quizId } = useParams();

  const saveToLocalStorage = useCallback(
    (survey, item, otherData) => {
      let curr;
      if (survey.currentPageNo + 1 < survey.visiblePageCount) {
        curr = survey.currentPageNo + 1;
      } else curr = survey.currentPageNo;
      let quizData = {
        data: survey.data,
        currPage: curr,
        registration_id: user.registrationId,
        ...otherData,
      };
      localStorage.setItem(
        `${item}#${user.userId}#${quizId}`,
        JSON.stringify(quizData)
      );
    },
    [quizId, user.registrationId, user.userId]
  );

  const getFromLocalStorage = useCallback(
    (item) => {
      let data = localStorage.getItem(
        `${item}#${user.userId}#${user.instanceId}#${quizId}#${user.registrationId}`
      );
      if (data) return JSON.parse(data);
      else return null;
    },
    [quizId, user.userId, user.instanceId, user.registrationId]
  );

  const autoSaveData = useCallback(async () => {
    if (model.state === "starting") return; // don't autosave on start page
    try {
      let oldData = getFromLocalStorage("quizData");
      if (oldData !== null) {
        await putQuizAttempt(
          quizId,
          user.registrationId,
          user.instanceId,
          oldData.data,
          { lastPageNo: oldData.currPage }
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    user.instanceId,
    getFromLocalStorage,
    model,
    user.registrationId,
    quizId,
  ]);

  useEffect(() => {
    const setQuizModel = async () => {
      try {
        if (!user.registrationId) {
          setError("No registration Id found!");
          return;
        }
        const quizData = await getQuiz(quizId);
        if (quizData?.quiz?.pages?.[0]) {
          let newModel = new ReactSurvey.Model(quizData.quiz);
          newModel.sendResultOnPageNext = true;

          let oldData = getFromLocalStorage("quizData");
          if (oldData !== null) {
            // if there is data in localStorage
            newModel.data = oldData.data; // fill data w/ localStorage
            newModel.currentPageNo = oldData.currPage;

            if (oldData.completed) newModel = showResults(newModel);
            else newModel.start();
          } else {
            let attempt = await getCompleteQuizAttempts(
              quizId,
              user.instanceId
            );
            if (attempt) {
              // if there is data in db, fill data w/ db data
              if (attempt.isCompleted) {
                newModel = showResults(newModel);
                newModel.data = attempt.answers;
                setIsCompleted(true);
              } else {
                // if answers exist (not {})
                if (Object.keys(attempt.answers).length > 0) {
                  // in order to be on page w/ last answered question
                  // set data
                  newModel.data = attempt.answers;
                  newModel.currentPageNo = attempt.lastPageNo;
                  newModel.start();
                }
              }
            }
          }
          setModel(newModel);
        } else {
          setError("No Quiz data found.");
        }
      } catch (err) {
        setError(err.message || "Unknown Error Occurred");
      }
    };

    setQuizModel();
  }, [quizId, getFromLocalStorage, user.instanceId, user.registrationId]);

  useEffect(() => {
    // useEffect for autoSave
    let autoSaveInterval;
    autoSaveInterval = setInterval(() => {
      if (!isCompleted) autoSaveData();
    }, INTERVAL_LENGTH);
    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [quizId, user.registrationId, autoSaveData, isCompleted]);

  const showResults = (newModel) => {
    // newModel.clear(false);
    newModel.mode = "display";
    newModel.questionsOnPageMode = "singlePage";

    // getResults(newModel);
    return newModel;
  };

  const onComplete = async (survey, options) => {
    let end = Date.now();
    let rightAnswers = survey.getCorrectedAnswerCount();
    let questionCount = survey.getQuizQuestionCount();
    let grade = Math.round((rightAnswers / questionCount) * 100);
    saveToLocalStorage(survey, "quizData", { grade: grade, completed: true });
    if (isNaN(grade)) grade = undefined; // if grade is NaN
    await putQuizAttempt(
      quizId,
      user.registrationId,
      user.instanceId,
      survey.data,
      {
        grade: grade,
        endTime: end,
        isCompleted: true,
      }
    );
    setIsCompleted(true);

    // review results
    survey.clear(false);
    survey.mode = "display";
    survey.questionsOnPageMode = "singlePage";
    // model.getAllQuestions().forEach((q) => renderCorrectAnswer(q));

    // getResults(survey);
  };

  const onPartialSend = (survey, options) => {
    saveToLocalStorage(survey, "quizData");
    autoSaveData();
  };

  const onValueChanged = (survey, options) => {
    saveToLocalStorage(survey, "quizData");
  };

  const onStarted = async (survey, options) => {
    let start = Date.now();
    // post attempt to db
    await completeQuizAttempt(quizId, user.instanceId, survey.data, {
      startTime: start,
    });
  };

  if (error) return <div>{error}</div>;

  if (model) {
    return (
      <ReactSurvey.Survey
        model={model}
        onValueChanged={onValueChanged}
        onPartialSend={onPartialSend}
        onStarted={onStarted}
        onComplete={onComplete}
        css={css}
      />
    );
  }
  return <div>loading</div>;
};

export default SurveyComponent;
