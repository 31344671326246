import { useState, useEffect } from "react";

// Hook
function useOrientation() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [orientation, setOrientation] = useState(
    window.innerHeight <= window.innerWidth ? "landscape" : "portrait"
  );
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setOrientation(
        window.innerHeight <= window.innerWidth ? "landscape" : "portrait"
      );
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return orientation;
}

export default useOrientation;
