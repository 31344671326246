import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getQuiz, getUploadUrl } from "../../api/quiz";
import CustomPropertyGridWrapper from "./components/CustomPropertyGridWrapper";
import CustomToolboxWrapper from "./components/CustomToolboxWrapper";
import blockList from "../../utils/blockList";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { Serializer } from "survey-core";
import * as SurveyReact from "survey-react-ui";
// import * as SurveyCreatorCore from "survey-creator-core";
import * as SurveyCreator from "survey-creator-react";

import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";

import "./QuizBuilder.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const options = {
  showTestSurveyTab: true,
  showJSONEditorTab: false,
  showLogicTab: false,
  showTranslationTab: false,
  showEmbeddedSurveyTab: false,
  haveCommercialLicense: true,
  // showErrorOnFailedSave: true,
  // isAutoSave: true,
  // showInvisibleElementsInTestSurveyTab: true,
  // showInvisibleElementsInPreviewTab: true,
  // showOptions: true,
  // useTabsInElementEditor: true,
  questionTypes: [
    "text",
    "checkbox",
    "radiogroup",
    "dropdown",
    "imagepicker",
    "image",
    "boolean",
    "signaturepad",
    "matrix",
    "matrixdropdown",
    // "html",
  ],
};

const creator = new SurveyCreator.SurveyCreator(options);
const curStrings = SurveyCreator.editorLocalization.getLocale("");

curStrings.pe.tabs.data = "Set Correct Answer";
curStrings.pe.maxTimeToFinish = "Maximum time to finish";
curStrings.pe.maxTimeToFinishPage = "Maximum time to finish a page";
curStrings.ed.surveyTypeName = "Quiz";
curStrings.ed.addNewQuestion = "Add Question";
curStrings.pe.surveyDescriptionPlaceholder = "Enter a description";
curStrings.pe.surveyTitlePlaceholder = "Input title here";
curStrings.qt.boolean = "True / False"; // change boolean question type to "True / False"

// when false, removes device simulator in preview tab
creator.showSimulatorInPreviewTab = false;

Serializer.addProperty("question", {
  name: "id:text",
  category: "general",
  readOnly: true,
});

Serializer.findProperty("question", "visible").displayName = "Show question"; // change "is visible?" to "show question"
Serializer.findProperty("question", "isRequired").displayName = "Must answer"; // change "is required?" to "must answer"

creator.onShowingProperty.add(function (sender, options) {
  if (Object.keys(blockList).includes(options.obj.getType())) {
    options.canShow = !blockList[options.obj.getType()].includes(
      options.property.name
    );
  }
});
// change question title 'boolean' to 'True / False'
creator.toolbox.getItemByName("boolean").title = "True / False";
creator.toolbox.getItemByName("boolean").tooltip = "True / False";

const matrixDynamicCellTypes = Serializer.findProperty(
  "matrixdynamic",
  "cellType"
);
matrixDynamicCellTypes.setChoices([
  "dropdown",
  "radiogroup",
  "checkbox",
  "boolean",
]);

// removing delete option from start page and  Disclaimer html
creator.onElementAllowOperations.add((sender, options) => {
  if (options.obj.name === "Start_Page" || options.obj.name === "Disclaimer") {
    options.allowDelete = false;
    // options.allowEdit = false;
    options.allowChangeType = false;
  }
});

// Add text to collapse button
const collapseAction = creator.sidebar.toolbar.actions.filter(
  (action) => action.id === "svd-grid-hide"
)[0];
collapseAction.showTitle = true;
collapseAction.title = "Collapse";

//Store property grid survey to use it later
var propertyGridSurvey;
creator.onSurveyInstanceCreated.add((sender, options) => {
  if (options.reason !== "property-grid") return;
  propertyGridSurvey = options.survey;
});

//Property grid survey is setup on after new element is selected
creator.onSelectedElementChanged.add((sender, options) => {
  if (!propertyGridSurvey) return;

  // propertyGridSurvey.onUpdatePanelCssClasses.add((sender, options) => {
  //   // .control contains css for dropdown **important**
  //   options.cssClasses.control = { background: "red", fontWeight: 600 };
  // });

  //Add new question
  const page = propertyGridSurvey.pages[0];
  const question = page.addNewQuestion("dropdown", "category", 0);
  question.titleLocation = "hidden";
  question.showOptionsCaption = false;
  //Set choices based on panels
  const categories = [];
  propertyGridSurvey.getAllPanels().forEach((p) => {
    if (p.elements.some((e) => e.visible))
      // only show panels that are allowed
      categories.push({ value: p.name, text: p.title });
    p.visible = false;
    p.expand();
    p.title = "";
  });
  question.choices = categories;

  //Show/Hide panels
  propertyGridSurvey.onValueChanged.add((sender, options) => {
    if (options.name !== "category") return;
    sender.getAllPanels().forEach((p) => {
      p.visible = question.value === p.name;
    });
  });
  question.value = categories[0].value;
  propertyGridSurvey = undefined;
});

export default function QuizBuilder() {
  const { quizId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const setModify = (val) => {
    setIsModified(val);
    return;
  };

  SurveyReact.ReactElementFactory.Instance.registerElement(
    "svc-property-grid",
    (props) => {
      props.quizId = quizId;
      props.setModify = setModify;
      props.isModified = isModified;
      return React.createElement(CustomPropertyGridWrapper, props);
    }
  );

  SurveyReact.ReactElementFactory.Instance.registerElement(
    `svc-adaptive-toolbox`,
    (props) => {
      return React.createElement(CustomToolboxWrapper, props);
    }
  );

  useEffect(() => {
    function onLeave(e) {
      // Cancel the event as stated by the standard.
      e.preventDefault();
      if (isModified) e.returnValue = "Do you really want to leave this page?";
    }

    window.addEventListener("beforeunload", onLeave);

    return () => {
      window.removeEventListener("beforeunload", onLeave);
    };
  }, [isModified]);

  useEffect(() => {
    creator.onUploadFile.add(async (creator, options) => {
      const urls = await Promise.all(
        options.files.map(async (file) => {
          const { uploadUrl, imageUrl } = await getUploadUrl(
            file.name,
            file.type,
            quizId
          );
          const config = {
            headers: {
              "Content-Type": file.type,
            },
          };
          await axios.put(uploadUrl, file, config);
          return imageUrl;
        })
      );
      options.callback("success", urls[0]);
    });

    creator.onModified.add((sender, options) => {
      setIsModified(true);
    });

    async function setQuizData() {
      try {
        setLoading(true);
        const quizData = await getQuiz(quizId);
        creator.JSON = quizData.quiz;
        if (quizData.editable === false) {
          creator.readOnly = true;
          setShowToast(true);
        } else creator.readOnly = false;
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    setQuizData();
  }, [quizId]); // eslint-disable-line

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowToast(false);
  };

  if (loading) return <div>...loading</div>;

  return (
    <React.Fragment>
      <React.StrictMode>
        <Snackbar
          open={showToast}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            This Quiz may not be edited, as there is at least one completed
            attempt
          </Alert>
        </Snackbar>
        <SurveyCreator.SurveyCreatorComponent creator={creator} />
      </React.StrictMode>
    </React.Fragment>
  );
}
