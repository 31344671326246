import _ from "lodash";
import "./Question.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";

const Question = ({ data, attempts }) => {
  const [answers, setAnswers] = useState([]);
  const [responses, setResponses] = useState(0); // the length of responses (answers) for the question
  const questionType = data.type;

  const setChoices = () => {
    // returns all the categories that should be in the Bar chart
    if (questionType === "radiogroup" || questionType === "dropdown") {
      return data.choices.sort().map((i) => i.value);
    } else if (questionType === "imagepicker") {
      return data.choices.sort().map((i) => i.value);
    } else if (questionType === "boolean") {
      return ["false", "true"];
    } else if (questionType === "text" || questionType === "checkbox") {
      return ["Correct", "Incorrect"];
    } else {
      return [];
    }
  };
  const choices = setChoices();

  const initMap = () => {
    // add all the choices into the map and sets the value to 0
    let map = new Map();
    choices.forEach((i) => {
      let k = i;
      if (questionType === "checkbox") {
        k = `${i}`;
      } else if (questionType === "boolean") {
        k = `${i}`;
      } else if (
        questionType === "matrix" ||
        questionType === "matrixdropdown"
      ) {
        k = `${JSON.stringify(i)}`;
      }
      map.set(k, 0);
    });
    return map;
  };

  const [map, setMap] = useState(initMap());

  const CORRECT_ANSWER = // gets and sets the correct answer for the question
    questionType === "checkbox"
      ? data.correctAnswer.sort()
      : data.correctAnswer;

  useEffect(() => {
    let ans = attempts.filter((i) => i.isCompleted); // remove incompleted attempts
    setResponses(ans.length);
    ans = ans
      .filter((i) => {
        // remove unanswered attempts
        if (i.answers && i.answers[data.name]) {
          return true;
        } else return false;
      })
      .map((i) => i.answers[data.name]);
    setAnswers(ans); // all responses for the question

    let ansMap = map;
    ans.forEach((e) => {
      // for each response, check if in the map and increment, else add to map
      let k = e;
      if (e && questionType === "checkbox") {
        if (_.isEqual(e, CORRECT_ANSWER)) k = "Correct";
        else k = "Incorrect";
      } else if (e && questionType === "boolean") {
        k = `${e}`;
      } else if (questionType === "text") {
        if (e === `${CORRECT_ANSWER}`) k = "Correct";
        else k = "Incorrect";
      }
      if (ansMap.has(k)) {
        let n = ansMap.get(k);
        ansMap.set(k, n + 1);
      } else {
        ansMap.set(k, 1);
      }
    });
    setMap(ansMap);
  }, [data, attempts, CORRECT_ANSWER, questionType, map]);

  const getCorrResponses = () => {
    // gets # of responses that were  correct
    let corrAttempts;
    if (questionType === "checkbox") {
      corrAttempts = answers.filter((i) => _.isEqual(i, CORRECT_ANSWER));
    } else if (questionType === "matrix" || questionType === "matrixdropdown") {
      corrAttempts = answers.filter((i) => {
        return _.isEqual(i, CORRECT_ANSWER);
      });
    } else {
      corrAttempts = answers.filter((i) => i === CORRECT_ANSWER);
    }
    return corrAttempts.length;
  };

  const getCategories = (r, c) => {
    // sets the categories that will be shown in the Bar chart
    if (questionType === "matrix") {
      let cols = data.columns.map((col) => {
        if (col && col.text) return col.text;
        else if (col && col.value) return col.value;
        else return col;
      });
      return cols.sort();
    } else if (questionType === "matrixdropdown") {
      return data.choices.sort();
    } else {
      if (questionType === "radiogroup" || questionType === "dropdown") {
        return data.choices.sort().map((i) => i.text);
      } else if (questionType === "imagepicker") {
        return data.choices.sort().map((i) => i.value);
      } else if (questionType === "boolean") {
        return ["false", "true"];
      } else if (questionType === "text" || questionType === "checkbox") {
        return ["Correct", "Incorrect"];
      } else {
        return Array.from(map.keys()).sort();
      }
    }
  };

  const getData = (r, c) => {
    // sets the data that will be shown in the Bar chart
    if (questionType === "matrix") {
      if (r && r.value) r = r.value;
      let matrixMap = new Map();
      data.columns.forEach((col) => {
        if (col && col.text) matrixMap.set(col.text, 0);
        else if (col && col.value) matrixMap.set(col.value, 0);
        else matrixMap.set(col, 0);
      });
      Array.from(map.keys())
        .map((k) => {
          return data.columns.find((i) => i.value === k[r]) || k[r];
        })
        .forEach((i) => {
          if (i && i.text) {
            if (matrixMap.has(i.text)) {
              let count = matrixMap.get(i.text);
              matrixMap.set(i.text, count + 1);
            }
          } else {
            if (matrixMap.has(i)) {
              let count = matrixMap.get(i);
              matrixMap.set(i, count + 1);
            }
          }
        });
      let d = Array.from(matrixMap.keys())
        .sort()
        .map((i) => {
          let corrVal = CORRECT_ANSWER[r];
          let corrTxt = data.columns.find((i) => i.value === corrVal)?.text;
          if ((corrTxt && i === corrTxt) || i === corrVal) {
            return { color: "#2aac35", y: matrixMap.get(i) };
          } else {
            if (matrixMap.get(i)) {
              return { y: matrixMap.get(i), color: "#cccccc" };
            } else return { y: 0, color: "#cccccc" };
          }
        });

      return d;
    } else if (questionType === "matrixdropdown") {
      let matrixMap = new Map();
      data.choices.sort().forEach((choice) => {
        matrixMap.set(choice, 0);
      });
      if (r && r.value) r = r.value;
      if (c && c.name) c = c.name;
      Array.from(map.keys())
        .map((i) => i[r][c])
        .forEach((i) => {
          if (matrixMap.has(i)) {
            let count = matrixMap.get(i);
            matrixMap.set(i, count + 1);
          }
        });

      let d = Array.from(matrixMap.keys())
        .sort()
        .map((i) => {
          if (i === CORRECT_ANSWER[r][c]) {
            return { color: "#2aac35", y: matrixMap.get(i) };
          } else {
            if (matrixMap.get(i)) {
              return { y: matrixMap.get(i), color: "#cccccc" };
            } else return { y: 0, color: "#cccccc" };
          }
        });

      return d;
    } else {
      let d = Array.from(map.keys())
        .sort()
        .map((i) => {
          if (
            i === `${CORRECT_ANSWER}` ||
            ((questionType === "text" || questionType === "checkbox") &&
              i === "Correct")
          ) {
            return { color: "#2aac35", y: map.get(i) };
          } else {
            if (map.get(i)) {
              return { y: map.get(i), color: "#cccccc" };
            } else return { y: 0, color: "#cccccc" };
          }
        });
      return d;
    }
  };

  const options = (r, c) => {
    // options for HighCharts
    return {
      accessibility: { enabled: false },
      chart: {
        type: "bar",
        height: 250,
        width: 700,
      },
      plotOptions: {
        series: {
          groupPadding: 0,
          pointWidth: 30,
          pointPadding: 0.1,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.y} (${((this.y / responses) * 100).toFixed(1)} %)`;
            },
            crop: false,
            overflow: "allow",
          },
        },
      },
      xAxis: {
        categories:
          questionType === "matrix" || questionType === "matrixdropdown"
            ? getCategories(r, c)
            : getCategories(),
      },
      yAxis: {
        visible: true,
        gridLineWidth: 0,
        title: {
          text: "Responses",
        },
        max: responses + 1,
      },
      title: {
        text: "",
      },
      series: [
        {
          name: "",
          data:
            questionType === "matrix" || questionType === "matrixdropdown"
              ? getData(r, c)
              : getData(),
        },
      ],
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
    };
  };

  return (
    <>
      <Paper className="paper">
        <div style={{ margin: "10px" }}>
          <h1 style={{ fontSize: 18 }}>{data.title}</h1>
          <div>
            <div className="corrResp">
              {getCorrResponses()} / {responses} Correct Responses
            </div>
            {questionType === "matrix" ? (
              data.rows.map((r, index) => (
                <div key={index} style={{ marginTop: 15 }}>
                  <div>{r && r.text ? r.text : r}</div>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options(r)}
                  />
                </div>
              ))
            ) : questionType === "matrixdropdown" ? (
              data.rows.map((row, index_r) =>
                data.columns.map((col, index_c) => (
                  <div key={`${index_r}_${index_c}`} style={{ marginTop: 15 }}>
                    <div>
                      Row: {row && row.text ? row.text : row}, Col:{" "}
                      {col && col.title ? col.title : col.name}
                    </div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options(row, col)}
                    />
                  </div>
                ))
              )
            ) : (
              <HighchartsReact highcharts={Highcharts} options={options()} />
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default Question;
