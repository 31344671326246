const NotFoundComponent = () => {
  return (
    <div style={{ margin: "10px" }}>
      <h1>Error 404!</h1>
      <p>This page does not exist!!</p>
    </div>
  );
};

export default NotFoundComponent;
