import axios from "axios";

export const login = async (accountId, token) => {
  const resp = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/v1//auth/login`,
    {
      params: {
        accountId,
        token,
      },
    }
  );
  return resp.data;
};

export const createExternalToken = async (
  role,
  quizId,
  route,
  duplicateQuizId = undefined,
  instanceId = undefined,
  registrationId = undefined,
  userId = undefined
) => {
  const resp = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/v1/auth/test`,
    {
      role,
      quizId,
      route,
      duplicateQuizId,
      instanceId,
      registrationId,
      userId,
    }
  );
  return resp.data;
};
