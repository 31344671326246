import { useParams } from "react-router-dom";
import { Tabs, Tab, styled } from "@mui/material";
import { useState } from "react";
import ResultsTab from "./components/ResultsTab/ResultsTab";
import QuestionTab from "./components/QuestionTab/QuestionTab";
import PreviewTab from "./components/PreviewTab/PreviewTab";

const ResultsComponent = () => {
  const { quizId } = useParams();
  const [tab, setTab] = useState("RESULTS");

  const MyTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: "2px solid #083971",
      height: 4,
    },
  });

  const MyTab = styled(Tab)({
    color: "black",
    fontWeight: "bold",
    "&.Mui-selected": {
      color: "black",
    },
  });

  return (
    <div style={{ marginTop: "20px" }}>
      <MyTabs value={tab} onChange={(e, v) => setTab(v)}>
        <MyTab className="tab" label="RESULTS" value="RESULTS" />
        <MyTab className="tab" label="QUESTIONS" value="QUESTIONS" />
        <MyTab className="tab" label="PREVIEW" value="PREVIEW" />
      </MyTabs>
      {tab === "RESULTS" && <ResultsTab quizId={quizId} />}
      {tab === "QUESTIONS" && <QuestionTab />}
      {tab === "PREVIEW" && <PreviewTab />}
    </div>
  );
};

export default ResultsComponent;
