import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import { useState } from "react";

//Routes
import Test from "./routes/test/Test";
import Quiz from "./routes/quiz/Quiz";
import QuizBuilder from "./routes/builder/QuizBuilder";
import SSO from "./routes/sso/index";
import ErrorBoundary from "./components/ErrorBoundary";
import InvalidComponent from "./routes/invalid/Invalid";
import NotFoundComponent from "./routes/not-found/NotFound";
import ResultsComponent from "./routes/results/Results";

function App() {
  const [title, setTitle] = useState("Quiz");
  const [combobulateUrl, setCombobulateUrl] = useState("/");
  let location = useLocation();
  const showHeader =
    location &&
    location.pathname &&
    ["", "builder", "invalid"].includes(location.pathname.split("/")[1]);

  return (
    <div className="App">
      <ErrorBoundary>
        {showHeader && <Header title={title} link={combobulateUrl} />}
        <div className="content">
          <Routes>
            <Route path="/quiz" element={<Quiz />}>
              <Route path=":quizId" element={<Quiz />} />
            </Route>
            <Route path="/builder" element={<QuizBuilder />}>
              <Route path=":quizId" element={<QuizBuilder />} />
            </Route>
            <Route
              path="/sso"
              element={<SSO setTitle={setTitle} setUrl={setCombobulateUrl} />}
            ></Route>
            <Route
              path="/results/:quizId"
              element={<ResultsComponent />}
            ></Route>
            {process.env.REACT_APP_STAGE !== "prod" && (
              <Route path="/" element={<Test />} />
            )}
            <Route path="/invalid" element={<InvalidComponent />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
        </div>
        {/* <Footer /> */}
      </ErrorBoundary>
    </div>
  );
}

export default App;
