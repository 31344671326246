import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import { useEffect, useState } from "react";
import { getQuiz } from "../../../../../api/quiz";
import {
  QuestionRadiogroupModel,
  QuestionCheckboxModel,
  QuestionMatrixModel,
  QuestionMatrixDropdownModel,
} from "survey-core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import * as Survey from "survey-core";
import * as ReactSurvey from "survey-react-ui";
import * as css from "survey-core/defaultV2.css";

import "./ViewAttempt.css";
import { Button } from "@mui/material";

Survey.StylesManager.applyTheme("defaultV2");

const ViewAttemptComponent = ({ attemptInfo, goBack }) => {
  const [model, setModel] = useState(undefined);
  const [error, setError] = useState("");

  const quizId = attemptInfo.quizIdUserIdRegistrationId.split("#")[0];

  useEffect(() => {
    const setQuizModel = async () => {
      try {
        const quizData = await getQuiz(quizId);
        if (quizData?.quiz?.pages?.[0]) {
          let newModel = new ReactSurvey.Model(quizData.quiz);

          if (attemptInfo.answers) {
            // if there is data in db, fill data w/ db data
            newModel = showResults(newModel);
            newModel.data = attemptInfo.answers;
            newModel.start();
          }
          setModel(newModel);
        } else {
          setError("No Quiz data found.");
        }
      } catch (err) {
        setError(err.message || "Unknown Error Occurred");
      }
    };

    setQuizModel();
  }, [quizId, attemptInfo.answers]);

  const correctStr = "Correct";
  const inCorrectStr = "Incorrect";
  function getTextHtml(text, str, isCorrect) {
    if (!text || text.indexOf(str) < 0) return undefined;
    return (
      text.substring(0, text.indexOf(str)) +
      "<span style='color:" +
      (isCorrect ? "green" : "red") +
      "'>" +
      str +
      "</span>"
    );
  }
  function isAnswerCorrect(q) {
    const right = q.correctAnswer;
    if (!right || q.isEmpty()) return undefined;
    var left = q.value;
    if (
      q instanceof QuestionMatrixModel ||
      q instanceof QuestionMatrixDropdownModel
    ) {
      return _.isEqual(left, right);
    }
    if (!Array.isArray(right)) return right === left;
    if (!Array.isArray(left)) left = [left];
    if (left.length !== right.length) return false;
    for (var i = 0; i < left.length; i++) {
      if (right.indexOf(left[i]) < 0) return false;
    }
    return true;
  }
  function renderCorrectAnswer(q) {
    if (!q) return;
    const isCorrect = isAnswerCorrect(q);
    if (!q.prevTitle) {
      q.prevTitle = q.title;
    }
    if (isCorrect === undefined) {
      q.title = q.prevTitle;
    }
    q.title = q.prevTitle + " " + (isCorrect ? correctStr : inCorrectStr);
  }

  const showResults = (newModel) => {
    // newModel.clear(false);
    newModel.mode = "display";
    newModel.questionsOnPageMode = "singlePage";

    // getResults(newModel);
    return newModel;
  };

  if (model) {
    model.getAllQuestions().forEach((q) => renderCorrectAnswer(q));
    model.onValueChanged.add((sender, options) => {
      renderCorrectAnswer(options.question);
    });

    model.onTextMarkdown.add((sender, options) => {
      if (options.element instanceof QuestionRadiogroupModel) {
        let selectedVal = options.element.selectedItem?.value;
        let selectedTxt = options.element.selectedItem?.text;
        if (options.name === "text" && selectedTxt === options.text) {
          if (options.element.correctAnswer === selectedVal) {
            let text = options.text;
            let html = `<span style="display: flex">${text}  ${ReactDOMServer.renderToString(
              <CheckBoxIcon color="success" />
            )}</span>`;
            options.html = html;
          } else {
            let text = options.text;
            let html = `<span style="display: flex">${text}  ${ReactDOMServer.renderToString(
              <DisabledByDefaultIcon sx={{ color: "red" }} />
            )}</span>`;
            options.html = html;
          }
        } else {
          let text = options.text;
          let html = getTextHtml(text, correctStr, true);
          if (!html) {
            html = getTextHtml(text, inCorrectStr, false);
          }
          if (!!html) {
            options.html = html;
          }
        }
      } else {
        if (options.element instanceof QuestionCheckboxModel) {
          let corrAns = options.element.correctAnswer;
          let selectedTxt = options.element.selectedItems?.map((i) => i.text);
          if (options.name === "text" && selectedTxt.includes(options.text)) {
            let corrTxt = options.element.selectedItems?.map((i) => {
              if (corrAns.includes(i.value)) return i.text;
              else return undefined;
            });
            if (corrTxt.includes(options.text)) {
              let text = options.text;
              let html = `<span style="display: flex">${text} ${ReactDOMServer.renderToString(
                <CheckBoxIcon color="success" />
              )}</span>`;
              options.html = html;
            } else {
              let text = options.text;
              let html = `<span style="display: flex">${text} ${ReactDOMServer.renderToString(
                <DisabledByDefaultIcon sx={{ color: "red" }} />
              )}</span>`;
              options.html = html;
            }
          }
        }
        let text = options.text;
        let html = getTextHtml(text, correctStr, true);
        if (!html) {
          html = getTextHtml(text, inCorrectStr, false);
        }
        if (!!html) {
          options.html = html;
        }
      }
    });
  }

  // getResults(model);

  if (error) return <div>{error}</div>;

  if (model) {
    return (
      <>
        <Button onClick={() => goBack()}>Go Back</Button>
        <ReactSurvey.Survey model={model} css={css} />
      </>
    );
  }
  return <div>loading</div>;
};

export default ViewAttemptComponent;
