import { getQuiz, getCompleteQuizAttempts } from "../../../../api/quiz";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Question from "./components/Question";

const QuestionTab = () => {
  const { quizId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [attemptsData, setAttemptsData] = useState([]);

  useEffect(() => {
    try {
      async function getAndSetData() {
        const quizData = await getQuiz(quizId);
        quizData.quiz.pages.forEach((page) => {
          if (page.elements && page.name !== "Start_Page") {
            setQuestions((questions) => questions.concat(page.elements));
          }
        });

        const resp = await getCompleteQuizAttempts(quizId, "");
        setAttemptsData(resp.attempts);
        // let attempts = resp.attempts.map((attempt) => {
        //   // let attempt_uId = attempt.quizIdUserIdRegistrationId.split("#")[1];
        //   // let user = resp.users.find((i) => i.userId === attempt_uId);
        //   console.log(attempts)
        // });
        // setData(attempts);
      }
      getAndSetData();
    } catch (err) {
      console.log(err);
    }
  }, [quizId]);

  return (
    <div style={{ margin: "10px" }}>
      {questions.map((q, i) => {
        return <Question key={i} data={q} attempts={attemptsData} />;
      })}
    </div>
  );
};

export default QuestionTab;
