import "./Header.css";
import { QuizBuilderIcon, CombobulateLogo } from "../svg/index";

export default function Header({ title, link }) {
  const isProd = process.env.REACT_APP_STAGE === "prod";
  const handleRoute = () => {
    if (isProd) return;
    window.location = link;
  };

  return (
    <div className="header-root">
      <div id="logo-container">
        <div id="main-logo">
          <CombobulateLogo
            onClick={handleRoute}
            sx={{
              cursor: isProd ? "auto" : "pointer",
              height: "25px",
              width: "150px",
            }}
          />
        </div>
        <div id="service-title">
          <QuizBuilderIcon sx={{ height: "15px", marginRight: "10px" }} />
          <div>{title}</div>
        </div>
      </div>
    </div>
  );
}
